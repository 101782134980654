@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@200;300;400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Urbanist", sans-serif;
}

.container {
  max-width: 1170px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

section {
  padding: 50px 0;
}

.sticky-header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 3px 3px -8px 3px #ddd;
  z-index: 99;
}

.h-menu {
  gap: 2rem;
}
.h-menu > *:hover {
  cursor: pointer;
}

.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .h-menu {
    z-index: 99;
    color: var(--black);
    position: absolute;
    top: 3rem;
    right: 4rem;
    background: white;
    font-weight: 500;
    gap: 2rem;
    padding: 0.8rem;
    border-radius: 10px;
    align-items: flex-start;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    transition: all 300ms ease-in;
  }
}
